import { BugIcon, ScrollText, BookOpen, VideoIcon, User2, Bomb, Home, BugOff } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function Sidebar({ sidebarOpen, setSidebarOpen }) {

    const location = useLocation();
    const { pathname } = location;
    const trigger = useRef(null);
    const sidebar = useRef(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!sidebar.current || !trigger.current) return;

            // Check if the click target is the button
            const isButtonClick = trigger.current.contains(target);

            if (!sidebarOpen || sidebar.current.contains(target) || isButtonClick) {
                // Prevent event propagation if the click is on the button
                if (isButtonClick) {
                    return;
                }
                setSidebarOpen(false);
            }
        };

        document.addEventListener('click', clickHandler);

        return () => document.removeEventListener('click', clickHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarOpen]);

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded);
        if (sidebarExpanded) {
            document.querySelector('body').classList.add('sidebar-expanded');
        } else {
            document.querySelector('body').classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    const handleSideBar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    // console.log(sidebarExpanded)
    return (
        <div>
            <div className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar lg:w-20 w-64  lg:sidebar-expanded:!w-64 2xl:!w-64 flex-shrink-0 bg-dark motion-reduce:transition-none dark:border-zinc-800 border-zinc-200 border-r p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
            >
                <div className="flex justify-between mb-10 pr-3 sm:px-2">
                    <button
                        ref={trigger}
                        className="lg:hidden text-gray-500 hover:text-gray-400"
                        onClick={() => handleSideBar()}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span className="sr-only">Close sidebar</span>
                        <svg
                            className="w-6 h-6 fill-current"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
                        </svg>
                    </button>
                    <NavLink exact to="/home" className="flex items-center gap-2">
                        <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="20" fill="#D4162D" />
                            <path d="M3.27871 17.519H6.59052L5.26579 24.7618H8.24642L9.57114 17.519H13.2141L13.5453 15.8729H3.60989L3.27871 17.519Z" fill="white" />
                            <path d="M17.5195 15.8729H14.5389L13.2141 22.4572L15.2012 24.7618H23.4807L24.8055 15.8729H21.8248L20.5001 23.1157H16.8571L16.5259 22.4572L17.5195 15.8729Z" fill="white" />
                            <path d="M24.4743 24.7618L25.4678 18.1774L27.7861 15.8729H36.0656L35.4032 18.8358H32.7538V17.519H29.1108L28.1173 18.1774L27.7861 20.1527H35.0721V21.7988H27.4549L27.1237 24.7618H24.4743Z" fill="white" />
                        </svg>
                        <span className={`font-amaranth text-stone-100 font-bold text-3xl ${sidebarExpanded ? "block" : 'hidden 2xl:block'}`}>takeUforward</span>
                    </NavLink>
                </div>

                <div className="space-y-4">

                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/home" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('home') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <Home className={`flex-shrink-0 h-5 w-5 ${pathname.includes('home') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('home') && 'text-brand_50'}`}>
                                    Home
                                </span>
                            </div>
                        </NavLink>
                    </div>

                    <div className="border-t border-gray-600 my-2"></div>

                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/problem" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('problem') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <ScrollText className={`flex-shrink-0 h-5 w-5 ${pathname.includes('problem') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('problem') && 'text-brand_50'}`}>
                                    Problem Bugs
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/video" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('video') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <VideoIcon className={`flex-shrink-0 h-5 w-5 ${pathname.includes('video') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('video') && 'text-brand_50'}`}>
                                    Video Bugs
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/tech" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('tech') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <Bomb className={`flex-shrink-0 h-5 w-5 ${pathname.includes('tech') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('tech') && 'text-brand_50'}`}>
                                    Technical Bugs
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/editorial" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('editorial') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <BookOpen className={`flex-shrink-0 h-5 w-5 ${pathname.includes('editorial') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('editorial') && 'text-brand_50'}`}>
                                    Editorial Bugs
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/others" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('others') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <BugIcon className={`flex-shrink-0 h-5 w-5 ${pathname.includes('others') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('others') && 'text-brand_50'}`}>
                                    Other Bugs
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    
                    <div className="border-t border-gray-600 my-2"></div>

                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/assigned" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('assigned') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <User2 className={`flex-shrink-0 h-5 w-5 ${pathname.includes('assigned') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('assigned') && 'text-brand_50'}`}>
                                    Assigned to me 
                                </span>
                            </div>
                        </NavLink>
                    </div>
                    <div className={`px-3 py-2 mt-2 rounded-lg last:mb-0`}>
                        <NavLink exact to="/bugTeam" className={`block text-gray-200 hover:text-white truncate transition duration-150 ${pathname.includes('bugTeam') && 'hover:text-gray-200'}`}>
                            <div className="flex items-center">
                                <BugOff className={`flex-shrink-0 h-5 w-5 ${pathname.includes('bugTeam') && 'stroke-brand_50'}`}/>
                                <span
                                    className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes('bugTeam') && 'text-brand_50'}`}>
                                    Bug-Bash Team 
                                </span>
                            </div>
                        </NavLink>
                    </div>
                </div>

                {/* Expand / collapse button */}
                <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
                    <div className="px-3 py-2">
                        <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                            <span className="sr-only">Expand / collapse sidebar</span>
                            <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                                <path className="text-gray-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                                <path className="text-gray-600" d="M3 23H1V1h2z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;