import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import bugNewReducer from '../slices/bugSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    bugs: bugNewReducer,
});

export default rootReducer;