// import { useSelector } from 'react-redux';
// import toast from 'react-hot-toast';
import cookie from 'cookie'
import { jwtDecode } from 'jwt-decode';

export function getUsernameFromToken(token) {
    try {

        const decoded = jwtDecode(token);

        return decoded.username;
    } catch (error) {
        return null;
    }
}
export const deleteCookie = (name) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() - (30 * 24 * 60 * 60 * 1000));

    document.cookie = `${name}=; expires=${expirationDate.toUTCString()}; path=/`;
}


export const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie)
    console.log("cookies: ", cookies)
    console.log("document.cookie: ", document.cookie)
    const value = cookies && cookies[name]

    if (value === undefined) {
        // Handle the case where the cookie doesn't exist
        return undefined; // or any other appropriate value or behavior
    }

    const hasToParse = Boolean((value && value[0] === '{') || value[0] === '[')

    return hasToParse ? JSON.parse(value) : value
}

export const setCookie = (name, value) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (30 * 24 * 60 * 60 * 1000));

    const cookieValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

    document.cookie = `${name}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;
}


export function setObjectInCookie(cookieName, objectValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Convert the object to a JSON string
    const jsonString = JSON.stringify(objectValue);

    // Encode the cookie name and value, and set the cookie
    const cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(jsonString)}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
}

export function getObjectFromCookie(cookieName) {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${encodeURIComponent(cookieName)}=`));

    if (cookieValue) {
        // Decode the cookie value and parse the JSON string to get the object
        const jsonString = decodeURIComponent(cookieValue.split('=')[1]);
        return JSON.parse(jsonString);
    }

    return null; // Return null if the cookie is not found
}

export function hasHtmlTags(inputString) {
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(inputString);
}

export const TOOLBAR_OPTIONS_NOTES = [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': [] }],
    [{ 'align': [] }]
];

export const TOOLBAR_OPTIONS_DISCUSSION = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block']
];
export const TOOLBAR_OPTIONS_DRAFT = [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block'],
    ["link", "image"]
];