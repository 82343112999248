/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { format } from "date-fns"
import { useDispatch, useSelector } from 'react-redux'
import ReportedBugsTable from '../../components/Table/BugReportsTable/BugsTable'
import { fetchBugsBySection } from '../../services/operations/bugs'

const ReportedBugs = ({ category, status, startDate, endDate, priority }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [content, setContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
   const bugsData = useSelector((state) => state.bugs[`${category}Bugs`]?.[status.trim().replace(' ', '_')] || {});

    const formatDate = (date) => {
        return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
    };

   useEffect(() => {
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

    dispatch(fetchBugsBySection(category, currentPage, status, formattedStartDate, formattedEndDate, priority));

}, [dispatch, category, currentPage, status, startDate, endDate, priority]);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };
   

    const renderCategoryBugs = (bugs) => {
        return (
            <div className="mb-8">
                {bugsData.data && bugsData.data.length > 0 ? (
                    <>
                        <ReportedBugsTable setModalOpen={setModalOpen} setContent={setContent} bugs={bugsData.data} category={category} currentPage={currentPage} />
                        <div className="flex justify-between mt-4">
                            <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                            <button
                                className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
                                onClick={handleNextPage}
                                disabled={currentPage * bugsData.data.length >= bugsData.totalRecords}
                            >
                                Next
                            </button>
                        </div>
                    </>
                ) : (
                    <div className='flex justify-center items-center text-3xl pt-4 dark:text-zinc-300'>
                        <button
                                className="px-4 py-2 bg-gray-500 hover:bg-gray-700 text-white rounded "
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Previous
                            </button>
                    </div>
                )}
            </div>
        );
    };

    return (
        <main className="dark:bg-dark font-primary h-screen overflow-y-auto bg-[#fafafa]">
            {renderCategoryBugs(bugsData.data)}
        </main>
    );
};

export default ReportedBugs;