import { bugEndpointsNew } from "../api";
import { apiConnector } from "../apiConnector";
import { setBugs, setBugDetails, setSections, setTeamMembers, setAssignedBugs, setStats, setLoading, setError} from "../../slices/bugSlice";
import toast from 'react-hot-toast'

const { GET_ALL_BUGS, GET_BUG_BY_ID, GET_BUGS_BY_SECTION, GET_BUG_COUNTS, UPDATE_BUG, ASSIGN_BUG_TO_USER, GET_ASSIGNED_BUGS, GET_BUG_STATS, GET_BUG_TEAM_MEMBERS, ADD_USER_TO_BUG_TEAM, ADD_COMMENT, SEND_EMAIL } = bugEndpointsNew;

export const fetchAllBugs = (status, startDate, endDate) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", GET_ALL_BUGS, {
                params: { status, startDate, endDate },
            });
            dispatch(setBugs(response.data.bugs));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching bugs"));
            toast.error("Error fetching bugs");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchBugById = (bugId) => {  
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", `${GET_BUG_BY_ID}/${bugId}`);
            dispatch(setBugDetails(response.data.bug));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching bug details"));
            toast.error("Error fetching bug details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchBugsBySection = (section, page = 1, status, startDate, endDate, priority) => {    
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", `${GET_BUGS_BY_SECTION}/${section}/bugs?page=${page}&status=${status}&startDate=${startDate}&endDate=${endDate}&priority=${priority}`);
            dispatch(
                setBugs({
                    section,
                    status,
                    data: response.data.bugs,
                    currentPage: page,
                    totalRecords: response.data.totalRecords,
                })
            );
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching bugs by section"));
            toast.error("Error fetching bugs by section");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateBugStatus = (bugId, status) => {   
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            await apiConnector("PUT", `${UPDATE_BUG}/${bugId}/status`, { status });
            toast.success("Bug status updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating bug status"));
            toast.error("Error updating bug status");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateBugPriority = (bugId, priority) => {   
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            await apiConnector("PUT", `${UPDATE_BUG}/${bugId}/priority`, { priority });
            toast.success("Bug priority updated");
            
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating bug priority"));
            toast.error("Error updating bug priority");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const assignBugToUser = (bugId, userId) => {  
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            await apiConnector("PUT", `${ASSIGN_BUG_TO_USER}/${bugId}/assign`, { userId });
            toast.success("Bug assigned successfully");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error assigning bug"));
            toast.error("Error assigning bug");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchAssignedBugs = (priority, status, startDate, endDate, currentPage) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", `${GET_ASSIGNED_BUGS}?page=${currentPage}&status=${status}&startDate=${startDate}&endDate=${endDate}&priority=${priority}`);
            dispatch(setAssignedBugs({
                data: response.data.bugs,
                currentPage: currentPage,
                totalRecords: response.data.totalRecords,
            }));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching assigned bugs"));
            toast.error("Error fetching assigned bugs");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchBugTeamMembers = () => {  
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", GET_BUG_TEAM_MEMBERS);
            dispatch(setTeamMembers(response.data.members));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching team members"));
            toast.error("Error fetching team members");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addUserToBugTeam = (username, setModalOpen) => {  
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            await apiConnector("POST", ADD_USER_TO_BUG_TEAM, { username });
            toast.success("User added to the bug team");
            if (setModalOpen) {
                setModalOpen(false);
            }
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding user to team"));
            toast.error("Error adding user to team");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addBugComment = (bugId, content, email, title) => {  
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", `${ADD_COMMENT}${bugId}/comments`, { content });
            dispatch(setBugDetails(response.data.bug));
            toast.success("Comment added successfully");
            
            dispatch(updateBugStatus(bugId, 'awaiting reply'))              // set bug status to 'awaiting reply' and trigger email to user       
            dispatch(sendEmailToUser(`TUF+ | Your BugId[${bugId}]`, `You have a new comment on the bug titled ${title}. Kindly review and help us resolve the bug soon. Here's the link: https://takeuforward.org/plus/bug-reports?bugId=${bugId}`, email))
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding comment"));
            toast.error("Error adding comment");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchDetailedBugCounts = (startDate, endDate) => { 
    return async (dispatch) => {
      dispatch(setLoading(true));
      try {
        // Fallback to default date range in frontend
        const currentDate = new Date();
        if (!startDate || !endDate) {
          startDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-01`;
          endDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
        }
  
        const response = await apiConnector(
          "GET",
          `${GET_BUG_COUNTS}?startDate=${startDate}&endDate=${endDate}`
        );
  
        dispatch(setSections(response.data.stats));
      } catch (error) {
        dispatch(setError(error.response?.data?.error || "Error fetching detailed counts"));
        toast.error("Error fetching detailed counts");
      } finally {
        dispatch(setLoading(false));
      }
    };
};
  
export const fetchEnhancedStats = (startDate, endDate) => { 
    return async (dispatch) => {
      dispatch(setLoading(true));
      try {
          // Fallback to default date range in frontend
          const currentDate = new Date();
          if (!startDate || !endDate) {
            startDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-01`;
            endDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
          }

        const response = await apiConnector(
          "GET",
          `${GET_BUG_STATS}?startDate=${startDate}&endDate=${endDate}`
        );
        dispatch(setStats(response.data.stats));
      } catch (error) {
        dispatch(setError(error.response?.data?.error || "Error fetching enhanced stats"));
        toast.error("Error fetching enhanced stats");
      } finally {
        dispatch(setLoading(false));
      }
    };
};  
  
export function sendEmailToUser(title, message, email) {
    return async () => {
        try {
            const response = await apiConnector("POST", SEND_EMAIL, { title, message, email })
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Failed to send email");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}
