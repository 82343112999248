/* eslint-disable no-unused-vars */
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import React, {useState} from "react";
import ReportedBugs from "./BugReports";
import DateRangePicker from "../../components/DateRangePicker";

const Tabs = ({category}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [priority, setPriority] = useState('');
    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // Start of current month
    });
    const [endDate, setEndDate] = useState(new Date()); // Today
    const [status, setStatus] = useState("open");

    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
    }

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    {/* Status Tabs */}
                    <div className="mb-6 flex gap-x-4 border-b">
                        {["open", "resolved", "awaiting reply"].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setStatus(tab)}
                                className={`px-4 py-2 ${
                                    status === tab
                                        ? "border-b-2 border-brand text-brand"
                                        : "text-gray-600"
                                }`}
                            >
                                {tab.toUpperCase()}
                            </button>
                        ))}
                    </div>
                    <div className="mb-4 flex items-center gap-x-4 justify-between">
                        {/* Priority filter */}
                        <div className="flex items-center gap-x-4">
                            <label htmlFor="priority" className="text-md font-medium text-gray-800 dark:text-zinc-200">Priority:</label>
                            <select
                                id="priority"
                                value={priority || ''}
                                onChange={handlePriorityChange}
                                className="form-select"
                            >
                                <option value="">All</option>
                                <option value="high">High</option>
                                <option value="mid">Medium</option>
                                <option value="low">Low</option>
                            </select>
                        </div>

                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>

                    <ReportedBugs category={category} priority={priority} status={status} startDate={startDate} endDate={endDate}/>
                </div>
            </div>
        </div>
    );
};

export default Tabs;