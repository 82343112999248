/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { sendEmailToUser } from '../../../services/operations/bugs';
import { useForm, Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import EmailModal from '../../Modal/EmailModal';
import ReactQuill from 'react-quill';
import { updateBugStatus, updateBugPriority, assignBugToUser, fetchBugTeamMembers } from '../../../services/operations/bugs'
import BugDetailsModal from '../../Modal/BugDetailsModal'
import { setEmailContent } from '../../../slices/bugSlice';

const BugsTableItem = (props) => {
    const {id,  status, title, num, priority, time, email, assignee } = props
    const [bugStatus, setBugStatus] = useState(status || '');
    const [bugPriority, setBugPriority] = useState(priority || '');

    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [bugModalOpen, setBugModalOpen] = useState(false);
    const [assignedTo, setAssignedTo] = useState(assignee || '');

    const { teamMembers} = useSelector((state) => state.bugs);

    const dispatch = useDispatch()

    useEffect(() => {
        if (!teamMembers.length) {
            dispatch(fetchBugTeamMembers());
        }
    }, [dispatch, teamMembers]);

    const emailMessage = `
    <p>Hi,</p><br/>
    <p>Thank you for reaching out to us.</p>
    <p>Our team has resolved the bug titled <strong>${title}</strong> successfully, and we hope it no longer affects the system's functionality.</p>
    <p>If you face any problems, do not hesitate to reach out to us.</p><br/>
    <p>Thanks and Regards,<br/>takeUForward Team</p>
    `;

    const { handleSubmit: handleEmailSubmit, control: emailControl, formState: { errors: emailErrors, isSubmitted: isEmailSubmitted } } = useForm({
        defaultValues: {
            sendEmailTitle: `TUF+ | Your BugId[${id}]`, 
            sendEmailMessage: emailMessage, 
        }
    });

    const handleStatusChange = (e) => {
        setBugStatus(e.target.value);
        dispatch(updateBugStatus(id, e.target.value));
        if(e.target.value === 'resolved'){
            setEmailModalOpen(true);
        } 
    };

    const handlePriorityChange = (e) => {
        setBugPriority(e.target.value);
        dispatch(updateBugPriority(id, e.target.value));
    };

    const handleAssignedToChange = (e) => {
        setAssignedTo(e.target.value);
        dispatch(assignBugToUser(id, e.target.value));
    };

    const handleView = (e) => {
        e.stopPropagation();
        setBugModalOpen(true);
      };
      

    const formatDate = useCallback((date) => {
        const d = new Date(date);
        return isNaN(d.getTime()) ? 'Invalid date' : d.toISOString().split('T')[0];
    // eslint-disable-next-line
    }, []);

    const handleSendEmail = async (data) => {
        const { sendEmailTitle, sendEmailMessage } = data;
        setIsSending(true);
        const toastId = toast.loading('Sending email. Please wait...');
        try {
            dispatch(sendEmailToUser(sendEmailTitle, sendEmailMessage, email));     
            toast.success('Email sent successfully!', { id: toastId });
        } catch (error) {
            toast.error('An error occurred while sending email.', { id: toastId });
        } 
        finally{
            setIsSending(false);
            setEmailModalOpen(false);
        }    
    }

    return (
        <tbody className="text-[13px] overflow-y-auto">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {num}.
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {title}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="w-2/3 bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={bugPriority}
                            onChange={handlePriorityChange}
                        >
                            <option value="high">High</option>
                            <option value="mid">Mid</option>
                            <option value="low">Low</option>
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {formatDate(time)}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={bugStatus}
                            onChange={handleStatusChange}
                        >
                            <option value="open">Open</option>
                            <option value="resolved">Resolved</option>
                            <option value="awaiting reply">Awaiting Reply</option>
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <select
                            className="bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all duration-300 ease-in-out"
                            value={assignedTo}
                            onChange={handleAssignedToChange}
                            >
                            <option value="">Unassigned</option>
                            {teamMembers.map((member) => (
                                <option key={member.id} value={member.id}>
                                {member.username}
                                </option>
                            ))}
                        </select>
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={(e) => handleView(e)} className="text-brand underline" type='button'>View</button>
                    </div>
                </td>
            </tr>
            <BugDetailsModal
                    setModalOpen={setBugModalOpen}
                    modalOpen={bugModalOpen}
                    bugId={id}
            />
            <EmailModal title={'Send Email To User'} setModalOpen={setEmailModalOpen} modalOpen={emailModalOpen}>
                <form onSubmit={handleEmailSubmit(handleSendEmail)} className='mt-6'>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Title</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailTitle"
                                control={emailControl}
                                render={({ field }) => (
                                    <input
                                        id="sendEmailTitle"
                                        type="text"
                                        name="sendEmailTitle"
                                        className="form-input w-full text-gray-300"
                                        placeholder="Title"
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Title is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailTitle && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailTitle.message}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Message</span>
                        <div className="w-full px-3">
                            <Controller
                                name="sendEmailMessage"
                                control={emailControl}
                                render={({ field }) => (
                                    <ReactQuill
                                        id="sendEmailMessage"
                                        theme="snow"
                                        name="sendEmailMessage"
                                        className="w-full text-gray-800 dark:text-zinc-200"
                                        placeholder="Email message to be sent."
                                        rows={5}
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: 'Message is required',
                                }}
                            />
                            {isEmailSubmitted && emailErrors.sendEmailMessage && <p className="text-red-600 text-sm italic">{emailErrors.sendEmailMessage.message}</p>}
                        </div>
                    </div>
                               
                    <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full px-3">
                            <button type="submit" className="btn-brand-2 w-full">Send Email</button>
                        </div>
                    </div>
                </form>
            </EmailModal> 
        </tbody>
    )
}

export default BugsTableItem