import React from 'react'
// import { setMember } from '../../../slices/bugSlice'
// import { useDispatch } from 'react-redux'
// import { deleteSession, getSession } from '../../../services/operations/sessions'

const BugTeamTableItem = (props) => {
    const { id, name } = props

    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {id}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center items-center">
                        {name}
                    </div>
                </td>   
                {/* <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button className="text-brand underline" type='button'>View</button>
                    </div>
                </td> */}
            </tr>

        </tbody >
    )
}

export default BugTeamTableItem