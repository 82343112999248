import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
    return (
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1.5rem' }}>
            <div>
                <label className="text-gray-700 dark:text-gray-300" style={{ marginRight: '0.5rem' }}>Start Date:</label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    className='bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-lg'
                />
            </div>
            <div>
                <label className="text-gray-700 dark:text-gray-300" style={{ marginRight: '0.5rem' }}>End Date:</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    className='bg-white dark:bg-zinc-800 border border-gray-300 dark:border-zinc-600 text-gray-800 dark:text-zinc-200 rounded-lg'
                />
            </div>
        </div>
    );
};

export default DateRangePicker;
