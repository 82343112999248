import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { login } from '../../services/operations/auth';
import { Eye, EyeOff, LockKeyhole } from 'lucide-react';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showPassword, setShowPassword] = useState(false);
    const { token } = useSelector((state) => state.auth)
    const { handleSubmit, control, formState: { errors } } = useForm({ mode: 'all' });

    useEffect(() => {
        if (token) {
            navigate('/home')
        }
    }, [navigate, token])

    const isPasswordValid = (value) => {
        return value.length >= 6 || 'Password must be at least 6 characters';
    };
    const handleOnSubmit = (data) => {
        const { username, password } = data;
        dispatch(login(username, password, navigate))
    }

    return (
        <div className="font-primary dark:bg-dark flex flex-col min-h-screen overflow-hidden">
            <Navbar />
            <main className="flex-grow">
                <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pt-28 pb-12">
                            <div className="max-w-3xl mx-auto text-center pb-8 md:pb-10">
                                <h1 className="text-5xl dark:text-zinc-200 font-extrabold leading-tight tracking-tighter">Welcome back!</h1>
                            </div>

                            <div className="max-w-sm mx-auto">
                                <form onSubmit={handleSubmit(handleOnSubmit)}>
                                    {/* Email Field Validation */}
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3 relative">
                                            <Controller
                                                name="username"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            className="form-input w-full text-gray-300 py-2 pl-10 pr-4"
                                                            placeholder='Username'
                                                            {...field}
                                                        />
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="absolute inset-1 right-auto left-[22px] top-[12px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><rect width="20" height="16" x="2" y="4" rx="2" /><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" /></svg>
                                                    </>
                                                )}
                                                rules={{
                                                    required: 'username is required',
                                                }}
                                            />
                                            {errors.username && <p className="text-red-600 text-sm italic">{errors.username.message}</p>}
                                        </div>
                                    </div>

                                    {/* Password Field Validation */}
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                        <div className="w-full px-3 relative">
                                            <Controller
                                                name="password"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            name="password"
                                                            className="form-input py-2 pl-10 pr-4 w-full text-gray-300"
                                                            placeholder="Password (at least 10 characters)"
                                                            {...field}
                                                        />
                                                        <LockKeyhole className="absolute inset-1 right-auto left-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                        {showPassword ? (
                                                            <Eye onClick={() => setShowPassword(!showPassword)} className="absolute inset-1 left-auto right-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                        ) : (
                                                            <EyeOff onClick={() => setShowPassword(!showPassword)} className="absolute inset-1 left-auto right-[22px] top-[10px] w-[20px] h-[20px] stroke-gray-300 dark:stroke-zinc-600" />
                                                        )}
                                                    </>
                                                )}
                                                rules={{
                                                    required: 'Password is required',
                                                    validate: {
                                                        isPasswordValid,
                                                    },
                                                }}
                                            />
                                            {errors.password && <p className="text-red-600 text-sm italic">{errors.password.message}</p>}
                                        </div>
                                    </div>


                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <button type="submit" className="btn-brand">Sign in</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default Login