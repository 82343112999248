import { Route, Routes } from 'react-router-dom'
import Login from './pages/Login/Login'
import NotFound from './pages/NotFound/NotFound'
import ProtectedRoute from './utils/ProtectedRoute'
import Tabs from './pages/ManageBugReports/BugsTab'
import HomePage from './pages/Home/HomePage'
import AddTeamMember from './pages/BugTeam/Team'
import AssignedBugs from './pages/ManageBugReports/AssignedBugs/AssignedBugs'
import { getCookie } from './utils/Utils'
import GetBug from './pages/ManageBugReports/GetBugById/GetBug'

const App = () => {

    console.log(getCookie('takeuforward_bugs'))
    return (
        <div>
            <Routes>
                <Route path='/' element={<Login />}/>
                <Route element={<ProtectedRoute />}>
                    <Route path='/home' element={<HomePage />} />
                    <Route path='/problem' element={<Tabs category='problem' />} />
                    <Route path='/video' element={<Tabs category='video' />} />
                    <Route path='/tech' element={<Tabs category='tech' />} />
                    <Route path='/others' element={<Tabs category='others' />} />
                    <Route path='/editorial' element={<Tabs category='editorial' />} />
                    <Route path='/bugTeam' element={<AddTeamMember />} />
                    <Route path='/assigned' element={<AssignedBugs />} />
                    <Route path='/get-bug/:id' element={<GetBug />} />
                </Route>
                <Route path='*' element={<NotFound />} />    
            </Routes>
        </div>
    )
}

export default App