import toast from "react-hot-toast"
import { setSignInData, setToken } from "../../slices/authSlice"
// import { deleteCookie } from "../../utils/Utils.jsx"
import { authEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
// import { setObjectInCookie } from "../../utils/Utils.jsx"

const { LOGIN_IN, LOGOUT_IN } = authEndpoints

export function login(username, password, navigate) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", LOGIN_IN, { username, password })

            if (response.data.success && response.data.userData) {
                // setObjectInCookie('userDataBugs', response.data.userData, 7)
                dispatch(setSignInData(response.data.userData))
                dispatch(setToken(response.data.token))
                toast.success("Login Successful")
                window.location.reload()
                navigate(`/home`)
            }
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }
}


export function logout(navigate) {
    return async () => {
        try {
            const response = await apiConnector("POST", LOGOUT_IN)

            if (response.data.success) {
                // deleteCookie('userDataBugs')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("takeuforward_bugs")
                toast.success("Logout Successful")
                window.location.reload()
                navigate(`/`)
            }
        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Session Expired")
                // deleteCookie('userDataBugs')
                setSignInData({})
                setToken(null)
                localStorage.removeItem("takeuforward_bugs")
                window.location.reload()
            }
        }
    }
}