import { createSlice } from "@reduxjs/toolkit";
import { getCookie, getUsernameFromToken } from "../utils/Utils.jsx";

const initialState = {
    // signinData: getObjectFromCookie("userDataBugs") ? getObjectFromCookie("userDataBugs") : {},
    username: getCookie('takeuforward_bugs') ? getUsernameFromToken(getCookie('takeuforward_bugs')) : null,
    loading: false,
    token: getCookie('takeuforward_bugs') ? getCookie('takeuforward_bugs') : null,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setSignInData(state, value) {
            state.signinData = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setToken(state, value) {
            state.token = value.payload;
        },
    },
});

export const { setSignInData, setLoading, setToken } = authSlice.actions;

export default authSlice.reducer;