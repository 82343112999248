import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = () => {

    const { token } = useSelector((state) => state.auth)

    // return (
    //     token ? <Outlet /> : <Navigate to='/' replace />


    // )
    return token ? (
        <>
            {/* {console.log("outlet", cookies)} */}
            <Outlet />
        </>
    ) : (
        <>
            {/* {console.log("fail", cookies)} */}
            <Navigate to="/" />
        </>
    );
}
export default ProtectedRoute;